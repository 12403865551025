import Vue from 'vue'
import VueRouter from 'vue-router'
import { useloggedUserInfo, moduleStore } from "../stores/UserInfo";
import Unauthorized from '../views/auth/Unauthorized.vue'
import DataService from "../services/DataService";

Vue.use(VueRouter)
const routes = [
    {
        path: '/unauthorized',
        name: 'Unauthorized',
        component: Unauthorized
    },
    {
        path: "/Review/Main",
        name: "Review",
        component: () => import("../views/auth/ReviewLeftTab.vue"),
        meta: {
            title: "Manage Review",
            breadcrumb: [{name: "Review"}],
        },
    },
    {
        path: "/Review/Show/:id/:userId",
        name: "Show",
        component: () => import("../views/Review/ReviewDetails/ReviewDetailLeftTab.vue"),
        meta: {
            title: "Manage Review",
          checkPrivilege:false,
          breadcrumb:[ { name: "Review", link: "/Review/Main" },
            { name: "provide Review" }],

        },
    },
    {
        path: "/Review/Index",
        name: "ReviewList",
        component: () => import("../views/Review/List.vue"),
        meta: {
            title: "Company Review Listing",
            breadcrumb: [{ name: "Company Review Listing" }],
        },
    },
    {
        path: "/Question/Index",
        name: "ReviewList",
        component: () => import("../views/Review/Question.vue"),
        meta: {
            title: "Manage Question",
            breadcrumb: [{ name: "Manage Question" }],
        },
    },
    {
        path: "/Review/ReviewListingForReviewer",
        name: "ReviewList",
        component: () => import("../views/Review/ReviewListingForReviewer.vue"),
        meta: {
          title: "Provide Review",
          breadcrumb: [{ name: "Provide Review" }],
        },
    },
    {
        path: "/Review/Report/:Id",
        name: "ReviewReport",
        component: () => import("../views/PerformanceReport/ReviewReport.vue"),
        meta: {
          title: "Review Report",         
          breadcrumb: [{ name: "Review Report" }],
        },    
    },
    {
        path: "/PerformanceReviewReport/Index",
        name: "PerformanceReviewReport",
        component: () => import("../views/PerformanceReport/PerformanceReviewReport.vue"),
        meta: {
          title: "Performance Review Report",         
          breadcrumb: [{ name: "Performance Review Report" }],
        },    
    },
    {
        path: "/Feedback/UserFeedbackListing",
        name: "Review",
         component: () => import("../views/Feedback/UserFeedbackListing.vue"),
        // meta: {
        //     title: "Provide Feedback",
        //     breadcrumb: [{ name: "Provide Feedback" }],
        // },
    },
]
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})


const DEFAULT_TITLE = 'Home';
router.beforeResolve((to, from, next) => {
    debugger;
    document.title = 'TALYGEN - ' + (to.meta.title || DEFAULT_TITLE);
    document.getElementById("divProgress1").style.display = 'none';
    document.getElementById("talygenfooter").style.display = 'block';
    if (to.path == '/unauthorized') {
        next()
    }
    else {
        const ustore = useloggedUserInfo();
        let app = ustore.GetUserInfo;
        if (app) {
            let isCheckPrivilege = true;
            if (to.meta.hasOwnProperty('checkPrivilege')) {
                isCheckPrivilege = to.meta.checkPrivilege;
            }
            if (isCheckPrivilege) {
                let controllerName = "", actionName = "";
                if (to.meta.hasOwnProperty('actionName') && to.meta.hasOwnProperty('controllerName')) {
                    controllerName = to.meta.controllerName;
                    actionName = to.meta.actionName;
                }
                else {
                    let pathArray = to.path.split("/");
                    if (pathArray.length > 2) {
                        controllerName = pathArray[1];
                        actionName = pathArray[2];
                    } else {
                        controllerName = pathArray[0];
                        actionName = pathArray[1];
                    }
                }
                if (controllerName != "" && actionName != "") {
                    DataService.CheckPrivilege(`controller=${controllerName}&action=${actionName}`).then((value) => {
                        if (value.data)
                            next()
                        else
                            //common.methods.ShowAlert('Sorry, You are not authorized to access this section.', "warning", true, "Alert");
                            next('/unauthorized');
                    },
                        (error) => {
                            console.log(error);
                        }
                    );
                } else {
                    next()
                }
            } else {
                next()
            }
        }
        else {
            //authService.authenticate(to.path).then(() => {
            console.log('authenticating a protected url:' + to.path);
            next();
            // });
        }
    }
});
export default router

